import * as React from "react"
import { graphql, Link } from "gatsby"
import marked from "marked"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <SEO title="Blogs" />
      <div className="dotted-background py-5">
        <div className="container-xl wrapper">
          <h1>{frontmatter.sara_blog_title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(frontmatter.sara_blog_text),
            }}
          ></div>
        </div>
      </div>
      <div className="container-xl wrapper my-5 py-5">
        <div className="row">
          {data.allMarkdownRemark.edges.map((blog, index) => (
            <div key={index} className="col-lg-4 col-sm-6 col-12 mb-4">
              <Link to={blog.node.fields.slug}>
                <div className="blog-card h-100 d-flex flex-column">
                  <img src={blog.node.frontmatter.image} alt=""></img>
                  <div className="blog-card-body">
                    <h5 className="pt-4 mx-3">{blog.node.frontmatter.title}</h5>
                    <p className="mx-3">{blog.node.frontmatter.short_text}</p>
                  </div>
                  <p className="read mx-3 text-right mt-auto">Lees nu →</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        sara_blog_title
        sara_blog_text
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/../" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            short_text
            text
            date
            image
          }
        }
      }
    }
  }
`
